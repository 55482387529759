var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { useEffect } from 'react';
import { useChatContext } from '../../../context/ChatContext';
export var useChannelUpdatedListener = function (setChannels, customHandler, forceUpdate) {
    var client = useChatContext('useChannelUpdatedListener').client;
    useEffect(function () {
        var handleEvent = function (event) {
            setChannels(function (channels) {
                var channelIndex = channels.findIndex(function (channel) { var _a; return channel.cid === ((_a = event.channel) === null || _a === void 0 ? void 0 : _a.cid); });
                if (channelIndex > -1 && event.channel) {
                    var newChannels = channels;
                    newChannels[channelIndex].data = event.channel;
                    return __spreadArray([], newChannels);
                }
                return channels;
            });
            if (forceUpdate) {
                forceUpdate();
            }
            if (customHandler && typeof customHandler === 'function') {
                customHandler(setChannels, event);
            }
        };
        client.on('channel.updated', handleEvent);
        return function () {
            client.off('channel.updated', handleEvent);
        };
    }, [customHandler]);
};
